import React, { useEffect, useContext } from "react";

import "./styles.scss";

import {
  FormToggle,
  FormWrapper,
  TitileHeading,
  FormInfoUser,
  ButtonUser,
  ButtonShop,
  BtnFileExcel,
  TextBtn,
} from "./Header.styles";
import userIcon from "../../../assets/icon/userIcon.png";
import downIcon from "../../../assets/icon/downIcon.png";
import sheets from "../../../assets/icon/sheets.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getUserInformation } from "../../../store/auth/authThunk";
import { MedalContext } from "../../../context/Context";
import rightIconbg from "../../../assets/icon/rightIconbg.png";
import { removeToken } from "../../../utils/localStorage";
import { authAction } from "../../../store/auth/authSlice";
import {
  getLinkReport,
  getReportLinkShipper,
} from "../../../services/reportServices";
import { getAuth, signOut } from "firebase/auth";
import { FaUser } from "react-icons/fa";
import { setTable, tableSelector } from "./tableSlice";

function Header() {
  const dispatch = useDispatch();
  const { setRender, fillterDate } = useContext(MedalContext);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getUserInformation());
  }, []);

  const auth = getAuth();
  const onClickLogout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        removeToken();
        console.log("active");
        setRender(Math.random());
        dispatch(authAction.logout());
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const currentTable = useSelector(tableSelector);

  const handleSubmit = async () => {
    if (currentTable === "totalStatistical") {
      getLinkReport(fillterDate).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    } else {
      getReportLinkShipper(fillterDate).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    }
  };

  const handleChangeTable = (table) => {
    dispatch(setTable(table));
  };

  useEffect(() => {
    dispatch(setTable("totalStatistical"));
  }, []);

  return (
    <div className="shadow-[0_2px_30px_-2px_rgba(0,0,0,0.3)]">
      <FormWrapper>
        <div>
          <TitileHeading>Thống kê</TitileHeading>
          <FormToggle>
            <ButtonUser
              className="ManagerBtn active"
              onClick={() => handleChangeTable("totalStatistical")}
            >
              Thống kê tổng
            </ButtonUser>
            <ButtonShop
              onClick={() => handleChangeTable("totalShipper")}
              className="ManagerBtn"
            >
              Thống kê Shipper
            </ButtonShop>
          </FormToggle>
        </div>
        <div>
          <FormInfoUser>
            <span className="text-[15px] ">{user.phone}</span>
            <FaUser
              color="#ffff"
              className="w-[32px] h-[32px] mx-[10px] bg-outline p-2 rounded-md"
            />
            <Dropdown>
              <Dropdown.Toggle>
                <img className="w-[15px] h-[7px]" src={downIcon} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div
                    onClick={onClickLogout}
                    className="flex justify-between items-center"
                  >
                    <div>Đăng xuất </div>
                    <img className="w-[30px]" src={rightIconbg} />
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </FormInfoUser>
          <BtnFileExcel onClick={handleSubmit}>
            <img src={sheets} className="w-[26px]" />
            <TextBtn>Xuất file Excel</TextBtn>
          </BtnFileExcel>
        </div>
      </FormWrapper>
    </div>
  );
}

export default Header;
