import tw from "tailwind-styled-components";

export const RainBox = tw.div`
    px-[20px]
    py-[10px]
    bg-[#0084FF]
    rounded-default
    w-[300px]
    h-[150px]
`
export const TimeBox = tw.div`
    px-[20px]
    py-[10px]
    bg-[#A93E00]
    rounded-default
    w-[300px]
    h-[150px]
    flex
    flex-col
    justify-between
`

TimeBox.Icon = tw.div`
    w-full
    flex
    justify-between
`

TimeBox.WrapTimeSetting = tw.div`
    flex
    gap-[15px]
    w-full
`

TimeBox.BoxTimeSetting = tw.div`
    flex
    gap-[5px]
    text-[17px]
    text-[#ffff]
    border-[1px]
    border-solid
    rounded-[5px]
    border-[rgba(255,255,255,0.7)]
    px-[15px]
    py-[12px]
    w-[calc(100%/2)]
`

export const RainIcon = tw.img`
    w-[60px]
`
export const Title = tw.div`
    font-semibold
    text-white
    text-[17px]
    mb-[16px]
`
export const SwichBtn = tw.img`
    w-[50px]
    hover:cursor-pointer
    float-right
`
export const Warraper = tw.div`
    flex
    flex-col
    w-full
    gap-[20px]
    p-[30px]
`

Warraper.Top = tw.div`
    flex
    w-full
    justify-between
`

Warraper.Bottom = tw.div`
    flex
    w-full
    justify-between
`

export const ShipBox = tw.div`
    px-[20px]
    py-[10px]
    bg-[#BE36E0]
    rounded-default
    flex-1
    h-[200px]
    mx-[20px]
`
export const Heading = tw.div`
    flex
    justify-between
`
export const EditIcon = tw.img`
    w-[25px]
    h-[25px]
    hover:cursor-pointer
`
export const Km = tw.div`
    flex
    my-[10px]
`
export const Span = tw.span`
    text-[17px]
    text-white
`
export const TitleSpan = tw.span`
    font-semibold
    text-white
    text-[17px]
    ml-[10px]
`
export const Ship = tw.div`

`
export const NotifyBox = tw.div`
    px-[20px]
    py-[10px]
    bg-outline
    rounded-default
    h-[150px]
    w-[360px]
    flex
    flex-col
    justify-between
`
export const NotifyHeader = tw.div`
    flex
`
export const NotifyIcon = tw.img`
    w-[22px]
    h-[27px]
    ml-[7px]
`
export const RightIcon = tw.img`
    w-[50px]
    self-end
    hover:cursor-pointer
`
export const ModalTime = tw.div`
    absolute
    top-[50%]
    left-[50%]
    translate-x-[-50%]
    translate-y-[-50%]
    p-[25px]
    rounded-[10px]
    bg-[#fff]
    flex
    flex-col
    gap-[20px]
`
ModalTime.Title = tw.span`
    text-[20px]
    font-medium
`
ModalTime.WrapperList = tw.div`
    flex
    flex-col
    gap-[15px]
`
ModalTime.BoxTime = tw.div`
    flex
    flex-col
    gap-[10px]
`
ModalTime.BoxTimeTitle = tw.span`
    text-[18px]
    text-[#000650]
    font-medium
`
ModalTime.InputTime = tw.input`
    w-[60px]
    h-[60px]
    border-[#B9D2FF]
    border-[1px]
    border-solid
    outline-none
    text-center
    rounded-[5px] 
`
ModalTime.ListBtn = tw.div`
    w-full
    flex
    gap-[10px]
`
ModalTime.BtnTime = tw.div`
    w-[calc(100%/2)]
    py-[15px]
    flex
    justify-center
    items-center
    rounded-[5px]
    text-[18px]
    font-medium
    text-[#fff]
    cursor-pointer
`