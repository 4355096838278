import tw from "tailwind-styled-components";

export const RF = {};

RF.Container = tw.div`
  flex
  items-end
  gap-[30px]
  mb-[10px]
  ml-[20px]
`;

RF.FormControl = tw.div`
`;

RF.Label = tw.div`
  flex
  gap-[10px]
  items-center
  text-[17px]
  font-bold
  mb-2
`;

RF.Button = tw.button`
  bg-outline
  rounded-[8px]
  w-[100px]
  h-[40px]
  text-[17px]
  text-white
  font-bold
  uppercase
`;
export const Input = tw.input`
  px-[10px]
  py-[7px]
  text-[15px]
  flex-1
  border-2
  border-[#ccc]
  rounded-tl-lg
  rounded-bl-lg
  outline-none
`
export const InputForm = tw.div`
  w-[300px]
  flex
  items-cennter
  justify-between
  mt-[20px] 
  ml-[20px] 
`
export const ImgForm = tw.div`
  flex 
  items-center 
  px-[22px] 
  bg-outline
  rounded-tr-lg
  rounded-br-lg
  hover:cursor-pointer
`