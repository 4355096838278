import Home from '../pages/Home/Home'

const ProtectedRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  
];
export default ProtectedRoutes;
