import AxiosClient from "./axiosClient";

const API_ENDPOINT = "order/";

export const getListOrderWithStt = (status) => {
  return AxiosClient.get(API_ENDPOINT, { params: status });
};

export const getListOrderByArea = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params });
}

export const getListOrderShopWithStt = (status) => {
  return AxiosClient.get(API_ENDPOINT + 'shop', {
    params: status,
  });
};

export const getOrderByShip = (status) => {
  return AxiosClient.get(API_ENDPOINT + 'ship', {
    params: status,
  });
};

export const updateOrder = (id, data) => {
  return AxiosClient.put(API_ENDPOINT + id, data);
};

export const detailOrder = (id) => {
  return AxiosClient.get(API_ENDPOINT + id);
};

export const postOrder = (data) => {
  return AxiosClient.post(API_ENDPOINT + "shop", data);
}

export const postOrderAdmin = (data) => {
  return AxiosClient.post(API_ENDPOINT + "admin", data);
}
