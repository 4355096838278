import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, signOut } from "firebase/auth";
import { getUserInformation } from "../../../store/auth/authThunk";
import Dropdown from 'react-bootstrap/Dropdown';
import { MedalContext } from '../../../context/Context'
import './styles.scss';
import {
    FormToggle, FormWrapper, TitileHeading,
    FormInfoUser, ButtonUser, ButtonShop
} from "./Header.styles"
import userIcon from '../../../assets/icon/userIcon.png'
import downIcon from '../../../assets/icon/downIcon.png'
import rightIconbg from '../../../assets/icon/rightIconbg.png';
import { removeToken } from "../../../utils/localStorage";
import { authAction } from "../../../store/auth/authSlice";
import Select from 'react-select'
import { setCodeArea } from "../../../store/area/area";
import { getListArea } from "../../../services/areaServices";
import { FaUser } from 'react-icons/fa';

function Header() {
    const dispatch = useDispatch();
    const { setRender } = useContext(MedalContext)
    const user = useSelector((state) => state.auth.user);
    const role = useSelector((state) => state.role.role);

    const [selectedOption, setSelectedOption] = useState('');
    const [listArea, setListArea] = useState([]);

    const dataOption = [{ value: "", label: "Tất cả khu vực" }];
    listArea.map((item) => {
        dataOption.push({
            value: item.codeArea,
            label: item.nameArea,
        })
    })

    const getArea = async () => {
        const res = await getListArea()
        return setListArea(res.data)
    }

    useEffect(() => {
        getArea()
        dispatch(getUserInformation());
    }, [])

    const auth = getAuth();
    const onClickLogout = () => {
        auth.signOut().then(() => {
            // Sign-out successful.
            removeToken();
            console.log('active')
            setRender(Math.random())
            dispatch(authAction.logout());
        }).catch((error) => {
            // An error happened.
        });
    };

    return (
        <div className="shadow-[0_2px_30px_-2px_rgba(0,0,0,0.3)] bg-white">
            <FormWrapper>
                <div>
                    <TitileHeading>Quản lý tài khoản</TitileHeading>
                    <FormToggle>
                        <ButtonUser className="ManagerBtn active">Quản lý user</ButtonUser>
                        <ButtonShop className="ManagerBtn">Quản lý shop</ButtonShop>
                        <ButtonShop className="ManagerBtn">Quản lý shipper</ButtonShop>
                        <ButtonShop className="ManagerBtn">Quản lý vị trí shipper</ButtonShop>
                        {/* {role == 'SUPERADMIN' && (
                            <Select
                                value={selectedOption}
                                options={dataOption}
                                defaultValue=''
                                placeholder="Tất cả khu vực"
                                onChange={(e) => {
                                    setSelectedOption(e);
                                    dispatch(setCodeArea(e.value));
                                }}
                                className="flex"
                                isSearchable={false}
                            />
                        )} */}
                    </FormToggle>
                </div>
                <div>
                    <FormInfoUser>
                        <span className="text-[15px] ">{user.phone}</span>
                        <FaUser color="#ffff" className="w-[32px] h-[32px] mx-[10px] bg-outline p-2 rounded-md" />
                        <Dropdown>
                            <Dropdown.Toggle>
                                <img className="w-[15px] h-[7px]" src={downIcon} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div onClick={onClickLogout} className="flex justify-between items-center">
                                        <div>Đăng xuất </div>
                                        <img className="w-[30px]" src={rightIconbg} />
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormInfoUser>
                </div>
            </FormWrapper>
        </div>
    )
}

export default Header