import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { MedalContext } from '../../../context/Context'
import downIcon from '../../../assets/icon/downIcon.png';
import { format } from "date-fns";

import {
    FormWrapper,
    Item,
    ItemTitle,
    FormInput,
    Input,
    InputTxt,
    StatusBtn,
    StatusBtnTitle,
    StatusBtnImg,
    FormStatus,
    FormBtn,
    Button

} from './Modals.styles'
import './Modals.scss';

function Modals() {

    const { isModalVisible, setIsModalVisible, order } = useContext(MedalContext)
    const [isDisabled, setIsDisabled] = useState(true)
    // console.log(order);
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        document.querySelectorAll('.inputForm').forEach((item, index) => {
            isDisabled ? item.disabled = true : item.disabled = false;
        });
    })
    // format money
    var formatter = new Intl.NumberFormat('vi', {
        style: 'currency',
        currency: 'VND',
    });
    // handle show status
    const showStatus = (status) => {
        switch (status) {
            case 'PENDDING':
                return <span className="text-[#0A00FF]">Chờ xác nhận</span>
            case 'WAITING_FOR_PRODUCT':
                return <span className="text-[#F9128F]">Chờ lấy hàng</span>
            case 'DELIVERY':
                return <span className="text-[#F67A28]">Đang giao</span>
            case 'COMPLETED':
                return <span className="text-[#0FE416]">Đã giao</span>
            case 'CANCELLED':
                return <span className="text-[#F41D1D]">Huỷ đơn</span>
            default:
                break;
        }
    }

    return (
        <Modal
            title="Chi tiết đơn hàng"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={900}
        >
            <FormWrapper>
                <Item>
                    <ItemTitle>Mã đơn hàng</ItemTitle>
                    <FormInput>
                        <Input value={order?.code} disabled />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Thời gian </ItemTitle>
                    <FormInput>
                        <span>{order ? format(new Date(order?.createdAt), "HH:mm") : ''}</span>
                        <span className="mx-[7px]"> | </span>
                        <span>{order ? format(new Date(order?.createdAt), "yyyy-MM-dd") : ''}</span>
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Sản phẩm</ItemTitle>
                    <FormInput>
                        <ul>
                            {order?.details.map((item, index) => (
                                <li key={index}>
                                    <span className="mr-1">x{item.amount} {item.productName}</span>
                                    {
                                        item.topping && item.topping.length > 0 || item.size ?
                                            <span>
                                                <span className="text-[#919191]">(</span>
                                                <span className="text-[#919191] mr-1">{item.size ? ` lựa chọn ${item.size.name}` : ''}</span>
                                                <span className="text-[#919191]">{item.topping && item.topping.length > 0 && item.size ? '/' : ''}</span>
                                                {
                                                    item.topping.filter((topping) => topping.amount > 0).map((topping, i) => {
                                                        let nameTopping = topping.name
                                                        if (i != item.topping.length - 1) {
                                                            nameTopping = nameTopping.concat(',')
                                                        }
                                                        return <span className="text-[#919191]" key={i}>{`+${topping.amount} ${nameTopping}`}</span>
                                                    })
                                                }
                                                <span className="text-[#919191]">)</span>
                                            </span>
                                            : ''
                                    }
                                </li>
                            ))}
                        </ul>
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Điểm đi</ItemTitle>
                    <FormInput>
                        <InputTxt className="inputForm" value={order?.from} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Điểm đến</ItemTitle>
                    <FormInput>
                        <InputTxt className="inputForm" value={order?.to} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Tên</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value={order?.fullName} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Số điện thoại</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value={order?.phone} />
                    </FormInput>
                </Item>
                {/* <Item>
                    <ItemTitle>Email</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value="quoctrieusw@gmail.com" />
                    </FormInput>
                </Item> */}
                <Item>
                    <ItemTitle>Quãng đường</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value={`${order?.distance}km`} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>{order?.type === "SHIP" ? "Phí dịch vụ" : "Tiền hàng"}</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value={order?.type === "SHIP" ? formatter.format(order?.serviceFee) : formatter.format(order?.totalPrice)} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Phí ship</ItemTitle>
                    <FormInput>
                        {/* <Input value="60.000đ" disabled /> */}
                        <span>{order ? formatter.format(order?.shipPrice) : ''}</span>
                        {order?.isRainy && <span className="ml-[15px] text-primary">+ 20%  trời mưa</span>}
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Tổng</ItemTitle>
                    <FormInput>
                        <Input className="inputForm" value={
                            order?.type === "SHIP" ? formatter.format(order?.shipPrice + order.serviceFee) :
                                formatter.format(order?.totalPrice + order?.shipPrice)} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Ghi chú</ItemTitle>
                    <FormInput>
                        <InputTxt className="inputForm" rows="auto" value={order?.note} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Trạng thái</ItemTitle>
                    <FormStatus>
                        <StatusBtn>
                            <StatusBtnTitle>{showStatus(order?.status)}</StatusBtnTitle>
                            <StatusBtnImg src={downIcon} />
                        </StatusBtn>
                    </FormStatus>
                </Item>
                {/* <FormBtn>
                    <Button>Cập nhật</Button>
                </FormBtn> */}
            </FormWrapper>
        </Modal>
    )
}

export default Modals