import tw from "tailwind-styled-components";

export const RightPage = tw.div`
  w-full
  h-full
  flex
  flex-col
  justify-center
  items-center
  relative
  bg-primary
`;
export const WrapForm = tw.div`
  w-[550px]
  flex
  flex-col
  items-center
  bg-white
  p-[26px] 
  rounded-default
`
export const WrapForm2 = tw.form`
  w-[550px]
  flex
  flex-col
  items-center
  bg-white
  p-[26px] 
  rounded-default
`
export const Heading = tw.div`
  text-[25px]
  font-bold
  text-center
  mb-[28px]
`
export const ImgForm = tw.div`
  flex 
  items-center 
  px-[22px] 
  bg-outline
  rounded-r-[10px]
  hover:cursor-pointer
`
export const Input = tw.input`
  px-[15px]
  py-[15px]
  text-[17px]
  flex-1
  outline-none
  border-l-2
  border-y-2
  border-[#707070]
  rounded-l-[10px]
`
export const InputForm = tw.div`
  w-full
  flex
  items-cennter
  justify-between
  overflow-hidden
`
export const BtnSubmit = tw.button`
  text-[20px] 
  font-bold 
  text-white 
  w-full 
  py-[14px] 
  bg-outline 
  rounded-default 
  text-center 
  mt-[15px] 
  hover:cursor-pointer
`