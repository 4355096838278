import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { MedalContext } from '../../../context/Context'
import { getDataShip, changeDataShip, getpercentShip, putpercentShip } from '../../../services/environment'
import CurrencyInput from 'react-currency-input-field';
import Notiflix from 'notiflix';
import { useForm } from "react-hook-form";

import {
    FormWrapper,
    Item,
    ItemTitle,
    FormInput,
    Input,
    FormBtn,
    Button,
    Span,

} from './Modals.styles'
import './Modals.scss';

function ModalEditShop() {

    const { isModalEditShipVisible, setIsModalEditShipVisible } = useContext(MedalContext)
    const [shipPrices1, setShipPrices1] = useState();
    const [shipPrices2, setShipPrices2] = useState();
    const [shipPrices3, setShipPrices3] = useState();
    const [shipPrices4, setShipPrices4] = useState();
    const [shipPrices5, setShipPrices5] = useState();
    const [shipPrices6, setShipPrices6] = useState();
    const [shipPrices7, setShipPrices7] = useState();
    const [shipPrices8, setShipPrices8] = useState();
    const [shipPrices9, setShipPrices9] = useState();
    const [shipPrices10, setShipPrices10] = useState();
    // % ship
    const [shippercent, setShippercent] = useState();

    // change ship data  
    const changeShipData = () => {
        const data = {
            "data": [
                {
                    "km": 1,
                    "price": Number(shipPrices1)
                },
                {
                    "km": 2,
                    "price": Number(shipPrices2)
                },
                {
                    "km": 3,
                    "price": Number(shipPrices3)
                },
                {
                    "km": 4,
                    "price": Number(shipPrices4)
                },
                {
                    "km": 5,
                    "price": Number(shipPrices5)
                },
                {
                    "km": 6,
                    "price": Number(shipPrices6)
                },
                {
                    "km": 7,
                    "price": Number(shipPrices7)
                },
                {
                    "km": 8,
                    "price": Number(shipPrices8)
                },
                {
                    "km": 9,
                    "price": Number(shipPrices9)
                },
                {
                    "km": 10,
                    "price": Number(shipPrices10)
                }
            ]
        }
        changeDataShip(data)
            .then((res) => {
                Notiflix.Notify.success('Thiết lập thành công');
                setIsModalEditShipVisible(false);
            })
    }

    const handleOk = () => {
        setIsModalEditShipVisible(false);
    };

    const handleCancel = () => {
        setIsModalEditShipVisible(false);
    };

    // get list ship  
    useEffect(() => {
        getDataShip()
            .then((res) => {
                setShipPrices1(res.data.data[0].price);
                setShipPrices2(res.data.data[1].price);
                setShipPrices3(res.data.data[2].price);
                setShipPrices4(res.data.data[3].price);
                setShipPrices5(res.data.data[4].price);
                setShipPrices6(res.data.data[5].price);
                setShipPrices7(res.data.data[6].price);
                setShipPrices8(res.data.data[7].price);
                setShipPrices9(res.data.data[8].price);
                setShipPrices10(res.data.data[9].price);
            })
    }, [])
    // handle % ship

    const getPerShip = () => {
        getpercentShip()
            .then(res => {
                setShippercent(res.data.precentShip);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getPerShip()
    }, [])

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        putpercentShip({
            "precentShip": data.percent,
            "isPrecentShip": true
        })
            .then(res => {
                Notiflix.Notify.success('Cập nhật thành công')
                getPerShip()
            })
            .catch(err => {
                console.log(err);
                Notiflix.Notify.failure('Cập nhật không thành công')
                getPerShip()
            })
    };
    return (
        <Modal
            title="Thiết lập phí ship"
            visible={isModalEditShipVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={'fit-content'}
        // style={{ top: '50%', transform: 'translateY(-50%)' }}
        >
            <FormWrapper className="flex-row">
                <div className='border-[1px] border-solid border-[#FF1393] p-[15px] rounded-[5px] h-fit flex flex-col gap-[20px]'>
                    <span className='text-[18px] font-medium text-[#000650] '>Điều chỉnh phí Ship</span>
                    <Item>
                        <ItemTitle>1Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices1}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices1(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>2Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices2}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices2(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>3Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices3}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices3(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>4Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices4}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices4(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>5Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices5}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices5(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>6Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices6}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices6(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>7Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices7}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices7(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>8Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices8}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices8(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>9Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices9}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices9(value)}
                            />
                        </FormInput>
                    </Item>
                    <Item>
                        <ItemTitle>10Km</ItemTitle>
                        <FormInput>
                            <CurrencyInput
                                className="outline-none"
                                id="input-example"
                                name="input-name"
                                decimalSeparator=","
                                suffix="đ"
                                groupSeparator="."
                                defaultValue={shipPrices10}
                                decimalsLimit={2}
                                onValueChange={(value) => setShipPrices10(value)}
                            />
                        </FormInput>
                    </Item>
                    <FormBtn>
                        <Button onClick={changeShipData}>Lưu</Button>
                    </FormBtn>
                </div>
                <form className='border-[1px] border-solid border-[#FF1393] p-[15px] rounded-[5px] h-fit flex flex-col gap-[20px]' onSubmit={handleSubmit(onSubmit)}>
                    <span className='text-[18px] font-medium text-[#000650]'>Điều chỉnh tăng % phí ship</span>
                    <div className='flex justify-between w-[60%] px-[20px] py-[10px] border-[#B9D2FF] rounded-[5px] border-[1px]'>
                        <span>+</span>
                        <div className='w-fit flex justify-end'>
                            <input
                                {...register("percent", {
                                    required: true,
                                    pattern: /([1-9][0-9]*)|0/
                                })}
                                className='w-[50%] text-center outline-none'
                                placeholder='0' value={`${shippercent}`}
                                onChange={e => setShippercent(e.target.value)}
                            />
                            <span>%</span>
                        </div>
                    </div>
                    {errors?.percent?.type === "required" && <p className='text-[#dc2626]'>*Vui lòng không bỏ tróng khi cập nhật</p>}
                    {errors?.percent?.type === "pattern" && <p className='text-[#dc2626]'>*Vui lòng nhập đúng định dạng</p>}
                    <Button className="w-[60%]">Lưu</Button>
                </form>
            </FormWrapper>
        </Modal>
    )
}

export default ModalEditShop