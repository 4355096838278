import React, { useContext, useState, useEffect, useMemo } from "react";
import { getListShop, changeSttShop } from "../../../services/shopServices";
import Dropdown from "react-bootstrap/Dropdown";
import { MedalContext } from "../../../context/Context";
import { Table } from "antd";
import lock from "../../../assets/icon/lock.png";
import unLock from "../../../assets/icon/unLock.png";
import lockGreen from "../../../assets/icon/lockGreen.png";
import downIcon from "../../../assets/icon/downIcon.png";
import searchIcon from "../../../assets/icon/searchIcon.png";
import dropdown from "../../../assets/icon/dropDown.png";
import editIcon from "../../../assets/icon/editIcon.png";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Input, InputForm, ImgForm } from "./TabUser.styles";
import {
  getListShipper,
  upPointShipper,
  getListShipperByArea,
  changeStatusShipper,
  deleteShipper,
} from "../../../services/shipperServices";
import { Modal } from "antd";
import Notiflix from "notiflix";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { IoEarthOutline } from "react-icons/io5";
import {
  socketDisconnect,
  socketEmit,
  socketOff,
  socketOn,
} from "../../../services/socketServices";
import { render } from "@testing-library/react";
import { HiUserAdd } from "react-icons/hi";
import ModalShipper from "./ModalShipper";
import { set } from "date-fns";

function TabShipper() {
  const user = useSelector((state) => state.auth.user);
  const codeArea = useSelector((state) => state.area.codeArea);
  const role = useSelector((state) => state.role.role);

  const [params, setsParams] = useState({
    codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    page: "1",
    limit: "10",
  });
  const [originalShippers, setOriginalShippers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [panage, setPanage] = useState(1);
  const [countPage, setCountPage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [currentPhoneShipper, setCurrentPhoneShipper] = useState();
  const [currentIdShipper, setCurrentIdShipper] = useState();
  const [currentPointShipper, setCurrentPointShipper] = useState();
  const [fillterNum, setFillterNum] = useState("");
  const [searchResultMessage, setSearchResultMessage] = useState("");
  const [id, setId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // set modal create shipper
  const [showShipper, setShipperShow] = useState(false);
  const handleShipperClose = () => setShipperShow(false);
  const handleShipperShow = () => setShipperShow(true);
  // useEffect(() => {
  //   updateLocationAddress();
  //   return () => {
  //     socketOff("address.update");
  //   };
  // }, []);

  // const updateLocationAddress = () => {
  //   socketOn("address.update", () => {
  //     getlist();
  //   });
  // };

  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1);
    } else {
      setPanage((prev) => prev - 1);
    }
  };

  const handleRight = () => {
    if (panage >= countPage) {
      return;
    } else {
      setPanage((prev) => prev + 1);
    }
  };

  const getlist = async () => {
    console.log("Fetching shippers with params:", params); // Debugging log
    await getListShipperByArea(params)
      .then((data) => {
        console.log("API response:", data); // Debugging log
        setOriginalShippers(data.data.shippers);
        setCountPage(Math.ceil(data.data.shippers.length / 5)); // Tính tổng số trang
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleType = async (id) => {
    setId(id.id);
    const res = await changeStatusShipper(id.id);
    console.log("res", res);
    if (res.status === 200) {
      Notiflix.Notify.success(`${res.data.response}`);
      // Cập nhật trạng thái của shipper trong state
      setOriginalShippers((prevShippers) =>
        prevShippers.map((shipper) =>
          shipper._id === id.id
            ? { ...shipper, status: !shipper.status }
            : shipper
        )
      );
    }
  };

  useEffect(() => {
    getlist();
  }, [params]);

  useEffect(() => {
    if (
      codeArea == null ||
      codeArea == "" ||
      (codeArea == undefined && role == "SUPERADMIN")
    ) {
      setsParams({ ...params, codeArea: undefined });
      return;
    }
    setsParams({
      ...params,
      codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    });
  }, [codeArea]);

  useEffect(() => {
    if (fillterNum === "") {
      getlist();
    }
  }, [fillterNum]);

  const filteredShippers = useMemo(() => {
    if (fillterNum === "") {
      return originalShippers;
    }
    return originalShippers.filter(
      (shipper) =>
        shipper.phone.includes(fillterNum) ||
        shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    );
  }, [fillterNum, originalShippers]);

  // Tự động tìm kiếm
  useEffect(() => {
    // Khi thay đổi giá trị fillterNum, sẽ reset về trang 1 và lọc dữ liệu
    setPanage(1);

    // Lọc dữ liệu dựa trên giá trị của fillterNum
    const filteredShippers = originalShippers.filter(
      (shipper) =>
        shipper.phone.includes(fillterNum) ||
        shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    );

    // Cập nhật dữ liệu hiển thị theo trang và số lượng trang
    setShippers(filteredShippers.slice(0 * 5, 5)); // Reset về trang đầu tiên
    setCountPage(Math.ceil(filteredShippers.length / 5)); // Cập nhật tổng số trang

    // Hiển thị thông báo nếu không tìm thấy shipper
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  }, [fillterNum, originalShippers]); // Lắng nghe sự thay đổi của fillterNum hoặc originalShippers

  useEffect(() => {
    setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5)); // Slicing dữ liệu theo trang
    setCountPage(Math.ceil(filteredShippers.length / 5)); // Cập nhật tổng số trang dựa trên kết quả tìm kiếm
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  }, [filteredShippers, panage]);

  const data = [];

  shippers.map((shipper, index) =>
    data.push({
      key: index,
      phone: shipper?.phone,
      fullName: shipper?.fullName,
      idCard: shipper?.idCard,
      point: shipper?.point,
      upPoint: [shipper?.phone, shipper?.point],
      status: shipper?.status,
      id: shipper?._id,
      address: shipper?.address,
    })
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
    },
    {
      title: "Tên shipper",
      dataIndex: "fullName",
    },
    {
      title: "CCCD",
      dataIndex: "idCard",
    },
    // {
    //   title: "Địa chỉ hiện tại",
    //   dataIndex: "address",
    // },
    // {
    //   title: "Điểm",
    //   dataIndex: "point",
    // },
    // {
    //   title: "Nạp điểm",
    //   dataIndex: "upPoint",
    //   render: ([phone, point]) => {
    //     return (
    //       <Button
    //         className="bg-[#00A56F] px-4 py-2 rounded-full"
    //         variant="success"
    //         onClick={(e) => {
    //           OpenModalUpPoint(phone);
    //         }}
    //       >
    //         <strong className="text-white">Nạp điểm</strong>
    //       </Button>
    //     );
    //   },
    // },

    {
      title: "Trạng thái",
      dataIndex: "status",
      render: (text, id) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="w-[150px] flex justify-between items-center px-[10px] py-[5px] border-[1px] border-[#ccc] rounded-[3px]">
              <span
                className={`font-bold ${
                  text === true ? "text-[#00E974]" : "text-[#FF1B1B]"
                } `}
              >
                {text === true ? "Đang mở" : "Đang khoá"}
              </span>
              <img
                className="w-[17px] h-[24px] mx-[10px]"
                src={text == true ? lockGreen : lock}
              />
              <img className="w-[15px] h-[9px]" src={downIcon} />
            </div>
          </Dropdown.Toggle>
          <div className="drop-status">
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleType(id)}>
                <div
                  className={`${
                    text == true ? " bg-[#FF1B1B]" : " bg-[#00E974]"
                  } text-white flex items-center justify-between font-bol px-[10px] py-[5px] py-[5px rounded-[3px]`}
                >
                  {text == true ? "Khoá" : "Mở"}
                  <img className="w-[17px] h-[24px]" src={unLock} />
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      ),
    },
    {
      dataIndex: "deleteShipper",
      render: (text, record) => {
        return (
          <Button
            className="px-4 py-2"
            variant="danger"
            onClick={(e) => {
              OpenModalDelete(record.id);
            }}
          >
            <strong className="text-red-600 underline">Xóa</strong>
          </Button>
        );
      },
    },
  ];

  const OpenModalUpPoint = (phone) => {
    setCurrentPhoneShipper(phone);
    setIsModalOpen(true);
  };

  const OpenModalDelete = (id) => {
    setCurrentIdShipper(id);
    setIsModalOpenDelete(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpenDelete(false);
  };

  const handleUpPoint = () => {
    upPointShipper({
      phone: currentPhoneShipper,
      point: Number(currentPointShipper),
    })
      .then((res) => {
        console.log(res);
        setCurrentPointShipper("");
        setIsModalOpen(false);
        getlist();
        Notiflix.Notify.success("Nạp điểm thành công");
      })
      .catch((err) => {
        console.log(err);
        Notiflix.Notify.failure("Có lỗi xảy ra");
      });
  };

  const handleDeleteShipper = async () => {
    try {
      const res = await deleteShipper(currentIdShipper);
      console.log("res", res);
      if (res.status === 200) {
        Notiflix.Notify.success("Xóa shipper thành công !");
        getlist();
        setIsModalOpenDelete(false);
      }
    } catch (error) {
      console.log("error when delete shipper", error);
      Notiflix.Notify.failure(
        "Có lỗi xảy ra khi xóa shipper, vui lòng thử lại sau"
      );
    }
  };

  const handleFilter = () => {
    // Lọc và phân trang lại dựa trên kết quả tìm kiếm
    setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5));
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  };

  return (
    <div className="manager-pane flex-1 flex flex-col">
      <div className="flex flex-col overflow-scroll">
        <div className="flex gap-4 mx-4 my-2 justify-between items-center">
          <InputForm>
            <Input
              placeholder="Nhập số điện thoại hoặc tên"
              onChange={(e) => setFillterNum(e.target.value)}
              value={fillterNum}
            />
            <ImgForm onClick={handleFilter}>
              <img className="w-[16px]" src={searchIcon} />
            </ImgForm>
          </InputForm>

          <div className="flex gap-4">
            <div
              className="flex gap-[13px] items-center px-[13px] py-[8px] bg-outline rounded-3xl border-[1px] border-solid border-[#F8C74F]"
              onClick={handleShipperShow}
            >
              <HiUserAdd size={30} className="mt-2" color="#fff" />
              <span className="whitespace-nowrap text-[#fff] text-[16px]">
                Tạo shipper
              </span>
            </div>
          </div>
        </div>

        {showShipper && (
          <ModalShipper
            handleShipperShow={handleShipperShow}
            handleShipperClose={handleShipperClose}
          />
        )}

        <div className="flex-1 h-full  overflow-y-scroll ">
          {searchResultMessage && (
            <div className="text-center text-red-500">
              {searchResultMessage}
            </div>
          )}
          <Table columns={columns} dataSource={data} />
        </div>

        <div className="w-full flex justify-center relative px-[20px] py-[20px]">
          <div className="w-fit flex text-center border-2 border-[#ccc]">
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleLeft}
            >
              &#x3c;
            </div>
            <div className="text-[23px] font-bold mx-[40px]">
              {countPage ? `${panage}/${countPage}` : panage}
            </div>
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleRight}
            >
              &#x3e;
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Nạp điểm cho shipper"
        visible={isModalOpen}
        onOk={handleUpPoint}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Nhập điểm"
          value={currentPointShipper}
          onChange={(e) => setCurrentPointShipper(e.target.value)}
        />
      </Modal>

      <Modal
        title="Thông báo"
        visible={isModalOpenDelete}
        onOk={handleDeleteShipper}
        onCancel={handleCancelDelete}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <p>Bạn có muốn xóa shipper này không?</p>
      </Modal>
    </div>
  );
}

export default TabShipper;
