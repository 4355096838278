import AxiosClient from "./axiosClient";

const API_ENDPOINT = "shop/";

export const getListShop = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params });
};

export const getListShopByArea = (area) => {
  return AxiosClient.get(API_ENDPOINT + `nameShops`, { params: { codeArea: area } })
}

export const getListShopArea = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params });
}

export const getInfoShop = (id) => {
  return AxiosClient.get(API_ENDPOINT + id);
};

export const changeInfoShop = (id, data) => {
  return AxiosClient.put(API_ENDPOINT + id, data);
};

export const changeSttShop = (id, data) => {
  return AxiosClient.put(API_ENDPOINT + `status/${id}`, { "status": data });
};
