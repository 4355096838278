import React, { useMemo } from "react";
import { Table, Pagination } from "antd";
import { useState } from "react";
import downIcon from "../../../assets/icon/downIcon.png";
import { Loading } from "notiflix";
import searchIcon from "../../../assets/icon/searchIcon.png";
import createUserIcon from "../../../assets/icon/createUserIcon.png";
import ticketAdd from "../../../assets/icon/ticketAdd.png";
import { Input, InputForm, ImgForm } from "./TabVoucher.style";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import closeIcon from "../../../assets/icon/closeIcon.png";
import Notiflix from "notiflix";
import "antd/dist/antd.min.css";
import {
  getListVoucher,
  deleteVoucher,
  postVoucher,
} from "../../../services/voucherServices";

import "./styles.scss";
import { IoTicket } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useCallback } from "react";

function TabVoucher() {
  const [panage, setPanage] = useState(1);
  const [params, setsParams] = useState({
    page: 1,
    limit: 6,
  });
  const [countPage, setCountPage] = useState();
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [voucher, setVoucher] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [isDetail, setIsDetail] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // const resetAsyncForm = useCallback(async () => {
  //   const result = await fetch("./api/formValues.json"); // result: { firstName: 'test', lastName: 'test2' }
  //   reset(result); // asynchronously reset your form values
  // }, [reset]);

  // useEffect(() => {
  //   resetAsyncForm();
  // }, [resetAsyncForm]);

  const onSubmit = (data) => {
    const convertData = {
      code: data.code,
      discount: +data.discount,
      countUserUsed: +data.limit,
      quantity: +data.quantity,
      startDate: data.startDate,
      expiryDate: data.endDate,
    };
    setLoading(true);
    console.log("ADD VOUCHER :", convertData);
    postVoucher(convertData)
      .then((res) => {
        console.log("#RES :", res);
        if (res.status === 200) {
          getVoucher();
          Notiflix.Notify.success("Thêm thành công");
          setLoading(false);
          handleShow();
        } else {
          Notiflix.Notify.failure(`${res.data.message}`);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleShow = () => {
    setIsModal(!isModal);
    reset();
  };
  console.log("#RECORD-SELECT :", selectedRecord);
  // set loading
  useEffect(() => {
    if (loading) Loading.pulse();
    else Loading.remove();
  }, [loading]);

  const columns = useMemo(() => [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Mã Voucher",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Giảm giá",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Trạng thái",
      dataIndex: "actions",
      render: (text, record) => <CustomDropdown record={record} />,
    },
  ], []);
  

  useEffect(() => {
    getVoucher();
  }, [params]);
  // handle left btn pange

  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1);
    } else {
      setPanage((prev) => prev - 1);
      setsParams({ ...params, page: panage - 1 });
    }
  };

  // handle right btn pange
  const handleRight = () => {
    if (panage >= countPage) {
      return;
    } else {
      setPanage((prev) => prev + 1);
      setsParams({ ...params, page: panage + 1 });
    }
  };
  const handleEditVoucher = (data) => {};

  const handleDeleteVoucher = async (record) => {
    setLoading(true);
    const deletedVoucher = voucher.filter(
      (voucher) => voucher.code !== record.code
    );
    setVoucher(deletedVoucher);
    await deleteVoucher(record.code);
    if (deletedVoucher) {
      setLoading(false);
    }
  };

  const handleAction = (action, record) => {
    // Handle actions here
    if (action === "details") {
      setIsDetail(!isDetail);
      setSelectedRecord(record);
      console.log(selectedRecord);
    } else if (action === "edit") {
      console.log("Edit clicked for record:", record);
    } else if (action === "delete") {
      handleDeleteVoucher(record);
    }
  };

  const handleValidateForm = (type, name) => {
    if (type === "required") {
      console.log("#ERRRR :", type);
      return (
        <p className="text-[#FF0000] text-[14px]">
          Vui lòng không bỏ trống ô này
        </p>
      );
    }
    if (type === "minLength") {
      return (
        <p className="text-[#FF0000] text-[14px]">Nhập tối thiểu 6 ký tự</p>
      );
    }
    // if (type === "maxLength") {
    //   return <p className="text-[#FF0000] text-[14px]">Nhập tối đa 6 ký tự</p>;
    // }
    if (name === "discount" && type === "min") {
      return (
        <p className="text-[#FF0000] text-[14px]">Giá tối thiểu là 1000</p>
      );
    }
    if (name === "quantity" && type === "min") {
      return <p className="text-[#FF0000] text-[14px]">Số lượng thiểu là 1</p>;
    }
    if (name === "limit" && type === "min") {
      return <p className="text-[#FF0000] text-[14px]">Số lượng thiểu là 1</p>;
    }
    if (type === "pattern") {
      return <p className="text-[#FF0000] text-[14px]">Không hợp lệ </p>;
    }
  };

  const CustomDropdown = ({ record }) => (
    <Dropdown>
      <Dropdown.Toggle variant="success" id={`dropdown-button-${record.key}`}>
        <img className="w-[12px] h-[7px] ml-[10px]" src={downIcon} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleAction("details", record)}>
          <span className="icon"></span> Chi tiết
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleAction("edit", record)}>
          <span className="icon"></span> Sửa
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleAction("delete", record)}>
          <span className="icon"></span> Xóa
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  // get list request
  const getVoucher = async () => {
    await getListVoucher(params)
      .then((voucher) => {
        console.log("voucher", voucher);
        setVoucher(voucher.data.data);
        setLoading(false);
        setCountPage(voucher.data.countPage);
        console.log("WORKING :");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getVoucher();
  }, [panage, params]);

  return (
    <>
      <div className="flex w-full justify-between items-center pr-[20px] cursor-pointer">
        <InputForm>
          <Input
            placeholder="Nhập voucher"
            // onChange={(e) => setFillterNum(e.target.value)}
            // value={fillterNum}
          />
          <ImgForm>
            <img className="w-[16px]" src={searchIcon} />
          </ImgForm>
        </InputForm>

        <div
          className="flex gap-[13px] items-center px-[13px] py-[8px] bg-outline rounded-3xl border-[1px] border-solid border-[#F8C74F]"
          onClick={handleShow}
        >
          <IoTicket size={30} className="mt-2" color="#fff" />
          <span className="whitespace-nowrap text-[#fff] text-[16px]">
            Tạo voucher
          </span>
        </div>
      </div>
      {/* modal detail start */}
      {selectedRecord && (
        <Modal
          show={isDetail}
          onHide={() => setIsDetail(!isDetail)}
          centered={true}
          size="xl"
        >
          <form className="px-[100px] py-[50px] flex flex-col gap-[50px] relative">
            <img
              src={closeIcon}
              className="absolute top-[15px] right-[15px] w-[37px] cursor-pointer"
              onClick={() => setIsDetail(!isDetail)}
            />
            <div className="flex flex-col gap-[50px]">
              <span className="font-bold text-[30px] w-full text-center">
                Chi Tiết Voucher
              </span>
              <div className="flex justify-start w-full gap-[30px]">
                <div className="flex w-1/3  flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">Mã Voucher</span>
                  <input
                    placeholder="Nhập mã voucher"
                    value={selectedRecord.code}
                    disabled
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("code", {
                    //   minLength: 6,
                    //   maxLength: 6,
                    //   required: true,
                    // })}
                    // onChange={handleOnChangeInputCode}
                  />
                  {handleValidateForm(errors?.code?.type)}
                </div>
                <div className="flex w-1/3  flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">Giá được giảm</span>
                  <input
                    type="number"
                    disabled
                    value={selectedRecord.discount}
                    placeholder="Nhập giá được giảm"
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("discount", {
                    //   required: true,
                    //   min: 1000,
                    // })}
                    // onChange={handleOnChangeInputDiscount}
                  />
                  {handleValidateForm(errors?.discount?.type, "discount")}
                </div>
                <div className="flex w-1/3  flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">Số lượng</span>
                  <input
                    type="number"
                    disabled
                    value={selectedRecord.quantity}
                    placeholder="Nhập số lượng"
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("quantity", {
                    //   required: true,
                    //   min: 1,
                    // })}
                    // onChange={handleOnChangeInputQuantity}
                  />
                  {handleValidateForm(errors?.quantity?.type, "quantity")}
                </div>
              </div>
              <div className="flex justify-start w-full gap-[30px]">
                <div className="flex w-1/3 flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">
                    Giới hạn sử dụng cho 1 người dùng
                  </span>
                  <input
                    type="number"
                    disabled
                    value={selectedRecord.countUserUsed}
                    placeholder="Nhập giới hạn sử dụng cho 1 người dùng"
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("limit", {
                    //   required: true,
                    //   min: 1,
                    // })}
                    // onChange={handleOnChangeInputLimit}
                  />
                  {handleValidateForm(errors?.limit?.type, "limit")}
                </div>
                <div className="flex w-1/3 flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">Ngày bắt đầu</span>
                  <input
                    type="date"
                    disabled
                    value={new Date(
                      selectedRecord.startDate
                    ).toLocaleDateString("en-CA")}
                    placeholder="Nhập giá được giảm"
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("startDate", {
                    //   required: true,
                    //   min: 1000,
                    // })}
                    // onChange={handleOnChangeInputStartDay}
                  />
                </div>
                <div className="flex w-1/3 flex-col gap-[15px]">
                  <span className="text-[20px] font-bold">Ngày kết thúc</span>
                  <input
                    type="date"
                    disabled
                    value={new Date(
                      selectedRecord.expiryDate
                    ).toLocaleDateString("en-CA")}
                    placeholder="Nhập giá được giảm"
                    className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                    // {...register("endDate", {
                    //   required: true,
                    // })}
                    // onChange={handleOnChangeInputEndDay}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center"></div>
          </form>
        </Modal>
      )}
      {/* modal detail  end */}

      {/* modal create user start */}
      <Modal show={isModal} onHide={handleShow} centered={true} size="xl">
        <form
          className="px-[100px] py-[50px] flex flex-col gap-[50px] relative"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img
            src={closeIcon}
            className="absolute top-[15px] right-[15px] w-[37px] cursor-pointer"
            onClick={handleShow}
          />
          <div className="flex flex-col gap-[50px]">
            <span className="font-bold text-[30px] w-full text-center">
              Tạo Voucher Khuyến Mãi
            </span>
            <div className="flex justify-start w-full gap-[30px]">
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">Mã Voucher</span>
                <input
                  placeholder="Nhập mã voucher"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("code", {
                    minLength: 6,
                    // maxLength: 6,
                    required: true,
                    pattern: {
                      value: /[a-zA-Z0-9]+/,
                      message: "invalid email address",
                    },
                  })}
                  // onChange={handleOnChangeInputCode}
                />
                {handleValidateForm(errors?.code?.type)}
              </div>
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">Giá được giảm</span>
                <input
                  type="number"
                  placeholder="Nhập giá được giảm"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("discount", {
                    required: true,
                    min: 1000,
                  })}
                  // onChange={handleOnChangeInputDiscount}
                />
                {handleValidateForm(errors?.discount?.type, "discount")}
              </div>
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">Số lượng</span>
                <input
                  type="number"
                  placeholder="Nhập số lượng"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("quantity", {
                    required: true,
                    min: 1,
                  })}
                  // onChange={handleOnChangeInputQuantity}
                />
                {handleValidateForm(errors?.quantity?.type, "quantity")}
              </div>
            </div>
            <div className="flex justify-start w-full gap-[30px]">
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">
                  {" "}
                  Giới hạn sử dụng cho 1 người
                </span>
                <input
                  type="number"
                  placeholder="Nhập giới hạn sử dụng cho 1 người"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("limit", {
                    required: true,
                    min: 1,
                  })}
                  // onChange={handleOnChangeInputLimit}
                />
                {handleValidateForm(errors?.limit?.type, "limit")}
              </div>
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">Ngày bắt đầu</span>
                <input
                  type="date"
                  placeholder="Nhập giá được giảm"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("startDate", {
                    required: true,
                    min: 1000,
                  })}
                  // onChange={handleOnChangeInputStartDay}
                />
              </div>
              <div className="flex w-1/3  flex-col gap-[15px]">
                <span className="text-[20px] font-bold">Ngày kết thúc</span>
                <input
                  type="date"
                  placeholder="Nhập giá được giảm"
                  className="p-[20px] text-[17px] w-full rounded-md border-[1px] border-solid border-[#878484] outline-none"
                  {...register("endDate", {
                    required: true,
                  })}
                  // onChange={handleOnChangeInputEndDay}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="px-[40px] py-[14px] text-[20px] font-bold text-white bg-gradient-to-r from-[#f0ab52] to-[#F8C74F] rounded cursor-pointer"
            >
              Xác nhận
            </button>
          </div>
        </form>
      </Modal>
      {/* modal create user end */}
      <div className="overflow-scroll h-[500px]">
        <Table
          pagination={{
            current: panage,
            pageSize: params.limit,
            total: countPage * params.limit,
            onChange: (page) => setPanage(page),
          }}
          columns={columns}
          dataSource={voucher}
        />

        {/* <ModalType id={id} /> */}
      </div>
      <div className="w-full flex justify-center ">
        <div className="w-fit flex text-center border-2 border-[#ccc]">
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleLeft}
          >
            &#x3c;
          </div>
          <div className="text-[23px] font-bold mx-[20px]">
            {panage}/{countPage}
          </div>
          <div
            className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
            onClick={handleRight}
          >
            &#x3e;
          </div>
        </div>
      </div>
    </>
  );
}

export default TabVoucher;
