import React, { useContext, useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import { MedalContext } from '../../../context/Context'
import downIcon from '../../../assets/icon/downIcon.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { updateOrder, detailOrder } from '../../../services/orderServices';
import { format } from "date-fns";
import {
    FormWrapper,
    Item,
    ItemTitle,
    FormInput,
    Input,
    InputTxt,
    StatusBtn,
    StatusBtnTitle,
    StatusBtnImg,
    FormStatus,
    FormBtn,
    Button,
    TitleStatus

} from './Modals.styles'
import './Modals.scss';

function ModalEdit() {

    const { isModalEditVisible, setIsModalEditVisible, order, idUpdate, setReRender } = useContext(MedalContext);
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [distance, setDistance] = useState();
    const [price, setPrice] = useState();
    const [total, setTotal] = useState();
    const [decription, setDecription] = useState();
    const [status, setStatus] = useState();

    // format money
    var formatter = new Intl.NumberFormat('vi', {
        style: 'currency',
        currency: 'VND',
    });

    const handleOk = async () => {
        const data = {
            "from": from,
            "to": to,
            "fullName": name,
            "phone": phone,
            "note": decription,
            "status": status,
            "distance": distance
        }
        updateOrder(idUpdate, data)
            .then(() => {
                setReRender(Math.random())
            })
        setIsModalEditVisible(false);
    };

    const handleCancel = () => {
        setIsModalEditVisible(false);
        detailOrder(idUpdate)
            .then((res) => {
                setFrom(res.data.from)
                setTo(res.data.to)
                setName(res.data.fullName)
                setPhone(res.data.phone)
                setDistance(res.data.distance)
                setPrice(res.data.details[0].price)
                setTotal(res.data.totalPrice)
                setDecription(res.data.note)
                setStatus(res.data.status)
            })
    };

    // dropdown status 
    const dropdownStatus = [
        {
            type: 'PENDDING',
            text: 'Chờ xác nhận',
            color: 'text-[#0A00FF]'
        },
        {
            type: 'WAITING_FOR_PRODUCT',
            text: 'Chờ lấy hàng',
            color: 'text-[#F9128F]'
        },
        {
            type: 'DELIVERY',
            text: 'Đang giao',
            color: 'text-[#F67A28]'
        },
        {
            type: 'COMPLETED',
            text: 'Đã giao',
            color: 'text-[#0FE416]'
        },
        {
            type: 'CANCELLED',
            text: 'Huỷ đơn',
            color: 'text-[#F41D1D]'
        },
    ]

    // handle show status
    const showStatus = (status) => {
        switch (status) {
            case 'PENDDING':
                return <span className="text-[#0A00FF]">Chờ xác nhận</span>
            case 'WAITING_FOR_PRODUCT':
                return <span className="text-[#F9128F]">Chờ lấy hàng</span>
            case 'DELIVERY':
                return <span className="text-[#F67A28]">Đang giao</span>
            case 'COMPLETED':
                return <span className="text-[#0FE416]">Đã giao</span>
            case 'CANCELLED':
                return <span className="text-[#F41D1D]">Huỷ đơn</span>
            default:
                break;
        }
    }

    useEffect(() => {
        if (idUpdate) {
            detailOrder(idUpdate)
                .then((res) => {
                    setFrom(res.data.from)
                    setTo(res.data.to)
                    setName(res.data.fullName)
                    setPhone(res.data.phone)
                    setDistance(res.data.distance)
                    setPrice(res.data.details[0].price)
                    setTotal(res.data.totalPrice)
                    setDecription(res.data.note)
                    setStatus(res.data.status)
                })
        } else { return }
    }, [idUpdate])

    return (
        <Modal
            title="Chỉnh sửa đơn hàng"
            visible={isModalEditVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={900}
        >
            <FormWrapper>
                <Item className="disabled">
                    <ItemTitle>Mã đơn hàng</ItemTitle>
                    <FormInput>
                        <Input value={order?.code} disabled />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Thời gian </ItemTitle>
                    <FormInput>
                        <span>{order ? format(new Date(order?.createdAt), "HH:mm") : ''}</span>
                        <span className="mx-[7px]"> | </span>
                        <span>{order ? format(new Date(order?.createdAt), "yyyy-MM-dd") : ''}</span>
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Sản phẩm</ItemTitle>
                    <FormInput>
                        <ul>
                            {order?.details.map((item, index) => (
                                <li key={index}>
                                    <span className="mr-1">x{item.amount} {item.productName}</span>
                                    {
                                        item.topping && item.topping.length > 0 || item.size ?
                                            <span>
                                                <span className="text-[#919191]">(</span>
                                                <span className="text-[#919191] mr-1">{item.size ? ` lựa chọn ${item.size.name}` : ''}</span>
                                                <span className="text-[#919191]">{item.topping && item.topping.length > 0 && item.size ? '/' : ''}</span>
                                                {
                                                    item.topping.map((topping, i) => {
                                                        let nameTopping = topping.name
                                                        if (i != item.topping.length - 1) {
                                                            nameTopping = nameTopping.concat(',')
                                                        }
                                                        return <span className="text-[#919191]" key={i}>{`+${topping.amount} ${nameTopping}`}</span>
                                                    })
                                                }
                                                <span className="text-[#919191]">)</span>
                                            </span>
                                            : ''
                                    }
                                </li>
                            ))}
                        </ul>
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Điểm đi</ItemTitle>
                    <FormInput>
                        <InputTxt onChange={(e) => setFrom(e.target.value)} value={from} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Điểm đến</ItemTitle>
                    <FormInput>
                        <InputTxt value={to} onChange={(e) => setTo(e.target.value)} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Tên</ItemTitle>
                    <FormInput>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Số điện thoại</ItemTitle>
                    <FormInput>
                        <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </FormInput>
                </Item>
                {/* <Item>
                    <ItemTitle>Email</ItemTitle>
                    <FormInput>
                        <Input value="quoctrieusw@gmail.com" />
                    </FormInput>
                </Item> */}
                <Item>
                    <ItemTitle>Quãng đường</ItemTitle>
                    <FormInput>
                        <Input value={distance} onChange={(e) => setDistance(e.target.value)} />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>{order?.type === "SHIP" ? "Phí dịch vụ" : "Tiền hàng"}</ItemTitle>
                    <FormInput>
                        <Input value={order?.type === "SHIP" ? formatter.format(order?.serviceFee) : formatter.format(order?.totalPrice)} disabled />
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Phí ship</ItemTitle>
                    <FormInput>
                        <span>{order ? formatter.format(order?.shipPrice) : ''}</span>
                        {order?.isRainy && <span className="ml-[15px] text-primary">+ 20%  trời mưa</span>}
                    </FormInput>
                </Item>
                <Item className="disabled">
                    <ItemTitle>Tổng</ItemTitle>
                    <FormInput>
                        <Input value={
                            order?.type === "SHIP" ? formatter.format(order?.shipPrice + order.serviceFee) :
                                formatter.format(order?.totalPrice + order?.shipPrice)} disabled />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Ghi chú</ItemTitle>
                    <FormInput>
                        <InputTxt
                            onChange={(e) => setDecription(e.target.value)}
                            rows="auto"
                            value={decription}
                        />
                    </FormInput>
                </Item>
                <Item>
                    <ItemTitle>Trạng thái</ItemTitle>
                    <FormStatus>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <StatusBtn>
                                    <StatusBtnTitle>{showStatus(status)}</StatusBtnTitle>
                                    <StatusBtnImg src={downIcon} />
                                </StatusBtn>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    dropdownStatus.map((status, index) => (
                                        <span key={index}>
                                            <Dropdown.Item key={index} onClick={() => setStatus(status.type)}>
                                                <TitleStatus className={status.color}>{status.text}</TitleStatus>
                                            </Dropdown.Item>
                                            <Dropdown.Divider key={index} />
                                        </span>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormStatus>
                </Item>
                <FormBtn>
                    <Button onClick={handleOk}>Cập nhật</Button>
                </FormBtn>
            </FormWrapper>
        </Modal>
    )
}

export default ModalEdit