import React, { useState, useEffect, useContext } from 'react';
import ReportFilter from './ReportFilter';
import ReportChart from './ReportChart';
import { MedalContext } from '../../../context/Context'
import { Loading } from "notiflix";
import { format } from "date-fns";
import { getReport, getReportKnifeFile } from "../../../services/reportServices";

function ReportChartTotal() {

    const [listReport, setListReport] = useState([]);
    const { setFillterDate } = useContext(MedalContext)

    const getData = () => {
        Loading.pulse();
        const dataParams = {
            startDate: format(new Date(startDate), "yyyy-MM-dd"),
            endDate: format(new Date(endDate), "yyyy-MM-dd"),
        };
        getReport(dataParams)
            .then((res) => {
                console.log("ReportChartTotal: ", res.data);
                setListReport(res?.data);
            })
            .finally(() => Loading.remove());
    };

    useEffect(() => {
        getData();
    }, []);

    const onPressedFilter = () => {
        getData();
    };

    const date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
    const [startDate, setStartDate] = useState(new Date(y, m, 1));
    const [endDate, setEndDate] = useState(new Date(y, m + 1, 0));
    useEffect(() => {
        setFillterDate({
            startDate: format(new Date(startDate), 'yyyy-MM-dd'),
            endDate: format(new Date(endDate), 'yyyy-MM-dd'),
        })
    }, [startDate, endDate])
    return (
        <div className="report-pane active">
            <ReportFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onPressedFilter={onPressedFilter}
            />
            <ReportChart report={listReport} />
        </div>
    )
}

export default ReportChartTotal