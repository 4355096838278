import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoadingFromAll } from "../../store/loading/loadingSelector";
import { Loading } from "notiflix";

const LoadingScreen = () => {
  const loadingFromAll = useSelector((state) => selectLoadingFromAll(state));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loadingFromAll !== isLoading) setIsLoading(loadingFromAll);
  }, [loadingFromAll]);

  useEffect(() => {
    if (isLoading) Loading.pulse();
    else Loading.remove();
  }, [isLoading]);

  return <></>;
};

export default LoadingScreen;
