import tw from "tailwind-styled-components";

export const Li = tw.li`
    list-none
    flex
    justify-start
    items-center
    py-[26px]
    px-[20px]
    hover:cursor-pointer
`
export const Ul = tw.ul`

`
export const TxtButton = tw.span`
    text-[16px]
    font-bold
    text-white
    ml-[20px]
`
export const TxtButtonActive = tw.span`
    text-[16px]
    font-bold
    text-primary
    ml-[20px]
    bg-white
`
export const LogoBtn = tw.img`
    w-[120px]
    rounded-[12px]
`
export const Iconbtn = tw.img`
    w-[35px]
    h-[35px]
`
export const Img = tw.img`
    w-[25px]
`