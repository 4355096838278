import React from 'react'
import { Col, Row } from 'antd';
import LeftPage from '../../components/LeftPage/LeftPage'
import RightPage from '../../components/RightPage/RightPage'

function Home() {
  return (
    <Row className='h-full'>
      <Col span={4}>
        <LeftPage />
      </Col>
      <Col span={20} className='h-full'>
        <RightPage />
      </Col>
    </Row>
  )
}

export default Home