import React, { useEffect, useState, useContext, useCallback, memo } from "react";
import ModalCreateOrderForm from "../ModalCreateOrderForm/ModalCreateOrderForm";
import { getListShopByArea } from "../../../../services/shopServices";
import { MedalContext } from "../../../../context/Context";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Select from 'react-select'
import { Table } from 'antd';

const CustomModal = (props) => {
    // define state
    const { reRender } = useContext(MedalContext);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selected, setSelected] = useState(false);
    const [shopNameSelected, setShopNameSelected] = useState('')
    const [historySearch, setHistorySearch] = useState([]);
    const [shop, setShop] = useState([]);
    const [shopAddressSelected, setShopAddressSelected] = useState('')
    const [shopPhoneSelected, setShopPhoneSelected] = useState('')

    // redux selector
    const role = useSelector((state) => state.role.role);
    const user = useSelector((state) => state.auth.user);
    const codeArea = useSelector((state) => state.area.codeArea)

    // props 
    const { show, handleClose } = props;

    const getListShop = async () => {
        await getListShopByArea(codeArea ?? user.codeArea)
            .then((res) => {
                setShop(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getListShop()
    }, [selected, reRender, historySearch, shopAddressSelected, shopNameSelected])

    // handle search shop for admin 
    const handleSearch = (value) => {
        for (let obj of shop) {
            if (value === obj?._id) {
                console.log(obj)
                setShopNameSelected(obj.name)
                setShopAddressSelected(obj.address)
                setShopPhoneSelected(obj.user.phone)
                setHistorySearch([data.find((item) => item.name === obj.name), ...historySearch])
                setSelected(!selected)
            }
        }
        setSelectedOption(null)
    }
    // table shop
    const data = [];
    const dataOption = [];

    // define columns table
    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'Tên shop',
            dataIndex: 'name',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
        },
    ];
    // map data shop to render 
    shop.map((shop, index) => {
        dataOption.push({
            value: shop?._id,
            label: shop?.name,
        })
        data.push({
            key: Math.random(index).toString(36).substr(2, 9),
            name: shop?.name,
            address: shop?.address?.address,
        })
    })

    return <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        dialogClassName="modal-createOder"
    >
        <div className="px-[60px] py-[5px] flex flex-col gap-[20px] h-full">
            <div>
                <span className="text-[20px] font-semibold uppercase">
                    Tạo đơn hàng mới
                </span>
            </div>
            {role !== 'SHOP' ? (
                selected
                    ? <ModalCreateOrderForm
                        goBack={() => setSelected(!selected)}
                        shopName={shopNameSelected}
                        address={shopAddressSelected}
                        shopPhone={shopPhoneSelected}
                        handleClose={handleClose}
                    />
                    : (<div className="flex p-[15px] rounded-[10px] bg-[#F5F5F5]">
                        <div className='flex-1 h-full'>
                            <Select
                                isDisabled={!dataOption.length}
                                value={selectedOption}
                                onChange={
                                    (value) => {
                                        setSelectedOption(value)
                                        handleSearch(value.value)
                                    }}
                                options={dataOption}
                            />
                            {historySearch.length > 0 ? (
                                <Table
                                    pagination={{ pageSize: 10 }}
                                    columns={columns}
                                    dataSource={historySearch}
                                />
                            ) : <Table columns={columns} dataSource={null} />}
                        </div>
                    </div>)
            ) : <ModalCreateOrderForm />}
        </div>
    </Modal >;
};

export default CustomModal;

