import React from 'react'
import Header from './components/Header'
import TabUser from './components/TabUser'

function RequestReview() {
  return (
    <div className="bg-[#F5F6FA] h-screen">
      <Header />
      <TabUser />
    </div>
  )
}

export default RequestReview