import React, { useContext, useState, useEffect, useMemo } from "react";
import { getListShop, changeSttShop } from "../../../services/shopServices";
import Dropdown from "react-bootstrap/Dropdown";
import { MedalContext } from "../../../context/Context";
import { Table } from "antd";
import lock from "../../../assets/icon/lock.png";
import unLock from "../../../assets/icon/unLock.png";
import lockGreen from "../../../assets/icon/lockGreen.png";
import downIcon from "../../../assets/icon/downIcon.png";
import searchIcon from "../../../assets/icon/searchIcon.png";
import dropdown from "../../../assets/icon/dropDown.png";
import editIcon from "../../../assets/icon/editIcon.png";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Input, InputForm, ImgForm } from "./TabUser.styles";
import {
  getListShipper,
  upPointShipper,
  getListShipperByArea,
  changeStatusShipper,
  deleteShipper,
} from "../../../services/shipperServices";
import { Modal } from "antd";
import Notiflix from "notiflix";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { IoEarthOutline } from "react-icons/io5";
import {
  socketDisconnect,
  socketEmit,
  socketOff,
  socketOn,
} from "../../../services/socketServices";
import { render } from "@testing-library/react";

function TabShipperLocation() {
  const user = useSelector((state) => state.auth.user);
  const codeArea = useSelector((state) => state.area.codeArea);
  const role = useSelector((state) => state.role.role);

  const [params, setsParams] = useState({
    codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    page: "1",
    limit: "10",
  });
  const [originalShippers, setOriginalShippers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [panage, setPanage] = useState(1);
  const [countPage, setCountPage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPhoneShipper, setCurrentPhoneShipper] = useState();
  const [currentPointShipper, setCurrentPointShipper] = useState();
  const [fillterNum, setFillterNum] = useState("");
  const [searchResultMessage, setSearchResultMessage] = useState("");
  const [id, setId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1);
    } else {
      setPanage((prev) => prev - 1);
    }
  };

  const handleRight = () => {
    if (panage >= countPage) {
      return;
    } else {
      setPanage((prev) => prev + 1);
    }
  };

  const getlist = async () => {
    console.log("Fetching shippers with params:", params); // Debugging log
    await getListShipperByArea(params)
      .then((data) => {
        console.log("API response:", data); // Debugging log
        setOriginalShippers(data.data.shippers);
        setCountPage(Math.ceil(data.data.shippers.length / 5)); // Tính tổng số trang
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getlist();
  }, [params, countPage]);

  useEffect(() => {
    if (
      codeArea == null ||
      codeArea == "" ||
      (codeArea == undefined && role == "SUPERADMIN")
    ) {
      setsParams({ ...params, codeArea: undefined });
      return;
    }
    setsParams({
      ...params,
      codeArea: role == "SUPERADMIN" ? codeArea : user.codeArea,
    });
  }, [codeArea]);

  useEffect(() => {
    if (fillterNum === "") {
      getlist();
    }
  }, [fillterNum]);

  const filteredShippers = useMemo(() => {
    if (fillterNum === "") {
      return originalShippers;
    }
    return originalShippers.filter(
      (shipper) =>
        shipper.phone.includes(fillterNum) ||
        shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    );
  }, [fillterNum, originalShippers]);



  // Tự động tìm kiếm
  useEffect(() => {
    // Khi thay đổi giá trị fillterNum, sẽ reset về trang 1 và lọc dữ liệu
    setPanage(1);

    // Lọc dữ liệu dựa trên giá trị của fillterNum
    const filteredShippers = originalShippers.filter(
      (shipper) =>
        shipper.phone.includes(fillterNum) ||
        shipper.fullName.toLowerCase().includes(fillterNum.toLowerCase())
    );

    // Cập nhật dữ liệu hiển thị theo trang và số lượng trang
    setShippers(filteredShippers.slice(0 * 5, 5)); // Reset về trang đầu tiên
    setCountPage(Math.ceil(filteredShippers.length / 5)); // Cập nhật tổng số trang

    // Hiển thị thông báo nếu không tìm thấy shipper
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  }, [fillterNum, originalShippers]); // Lắng nghe sự thay đổi của fillterNum hoặc originalShippers




  useEffect(() => {
    setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5)); // Slicing dữ liệu theo trang
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  }, [filteredShippers, panage]);

  const data = [];

  shippers.map((shipper, index) =>
    data.push({
      key: index,
      phone: shipper?.phone,
      fullName: shipper?.fullName,
      idCard: shipper?.idCard,
      point: shipper?.point,
      upPoint: [shipper?.phone, shipper?.point],
      id: shipper?._id,
      address: shipper?.address,
      status: shipper?.statusShipper,
    })
  );
  console.log("Shipper data:", shippers);

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    // },
    {
      title: "Tên shipper",
      dataIndex: "fullName",
    },
    {
      title: "Địa chỉ hiện tại",
      dataIndex: "address",
    },
    // {
    //   title: "Số điện thoại",
    //   dataIndex: "phone",
    // },
    // {
    //   title: "Điểm",
    //   dataIndex: "point",
    // },
    // {
    //   title: "Nạp điểm",
    //   dataIndex: "upPoint",
    //   render: ([phone, point]) => {
    //     return (
    //       <Button
    //         className="bg-[#00A56F] px-4 py-2 rounded-full"
    //         variant="success"
    //         onClick={(e) => {
    //           OpenModalUpPoint(phone);
    //         }}
    //       >
    //         <strong className="text-white">Nạp điểm</strong>
    //       </Button>
    //     );
    //   },
    // },

    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      render: (text, id) => (
        <div variant="success" id="dropdown-basic">
          <div className="w-[150px] flex justify-between items-center px-[10px] py-[5px]  rounded-[3px]">
            <span
              className={`font-bold ${
                text === true ? "text-[#00E974]" : "text-[#FF1B1B]"
              } `}
            >
              {text === true ? "Đang mở" : "Đang tắt"}
            </span>
          </div>
        </div>
      ),
    },
  ];

  const handleFilter = () => {
    console.log("Searching for phone number or name:", fillterNum); // Debugging log
    setShippers(filteredShippers.slice((panage - 1) * 5, panage * 5)); // Slicing dữ liệu theo trang
    if (filteredShippers.length === 0) {
      setSearchResultMessage("Không có shipper nào được tìm thấy.");
    } else {
      setSearchResultMessage("");
    }
  };

  const handleReloadLocation = () => {
    Notiflix.Loading.pulse();

    socketEmit("refresh_address_to_shipper");

    // Đợi 2 giây cho app lấy tất cả vị trí của shipper
    setTimeout(() => {
      getlist();
    }, 2000);

    Notiflix.Loading.remove();
  };

  return (
    <div className="manager-pane flex-1 flex flex-col">
      <div className="flex flex-col overflow-scroll">
        <div className="flex gap-4 mx-4 my-2 justify-between items-center">
          <InputForm>
            <Input
              placeholder="Nhập số điện thoại hoặc tên"
              onChange={(e) => setFillterNum(e.target.value)}
              value={fillterNum}
            />
            <ImgForm onClick={handleFilter}>
              <img className="w-[16px]" src={searchIcon} />
            </ImgForm>
          </InputForm>

          <div
            className="flex gap-[4px] items-center px-2 py-3 rounded-xl border-[1px] border-solid border-[#000] cursor-pointer"
            onClick={handleReloadLocation}
          >
            <IoEarthOutline size={24} color="#000" />
            <span className="whitespace-nowrap text-[14px]">
              Cập nhật lại vị trí hiện tại
            </span>
          </div>
        </div>

        <div className="flex-1 h-full  overflow-y-scroll ">
          {searchResultMessage && (
            <div className="text-center text-red-500">
              {searchResultMessage}
            </div>
          )}
          <Table columns={columns} dataSource={data} />
        </div>

        <div className="w-full flex justify-center relative px-[20px] py-[20px]">
          <div className="w-fit flex text-center border-2 border-[#ccc]">
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleLeft}
            >
              &#x3c;
            </div>
            <div className="text-[23px] font-bold mx-[40px]">
              {countPage ? `${panage}/${countPage}` : panage}
            </div>
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleRight}
            >
              &#x3e;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabShipperLocation;
