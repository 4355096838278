import React, { useState, useContext, useEffect, useRef } from "react";
import "./login.scss";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { firebase, auth } from "../../firebase/Firebase";
import Notiflix from "notiflix";
import {
  login,
  loginwidthzalo,
  validateOtZalo,
} from "../../services/authServices";
import { getMessaging, getToken } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// import { messaging } from '../../firebase/Firebase'
// import { getMessaging } from "firebase/messaging";
import {
  RightPage,
  WrapForm,
  WrapForm2,
  Heading,
  InputForm,
  Input,
  ImgForm,
  BtnSubmit,
} from "./Login.styles";

import logo from "../../assets/icons/logo.png";
import right from "../../assets/icon/right.png";
import OTPInput, { ResendOTP } from "otp-input-react";
import { MedalContext } from "../../context/Context";
import { setToken, getTokens } from "../../utils/localStorage";
import { getUser } from "../../services/authServices";
import { setRole } from "../../store/role/role";
import { FaArrowRight } from "react-icons/fa";
export const Login = () => {
  const VN = "+84";
  const [phoneNumber, setPhoneNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [step, setStep] = useState("INPUT_PHONE_NUMBER");
  const [result, setResult] = useState("");
  const [tab, setTab] = useState(true);
  const [show, setShow] = useState(false);
  const { setRender } = useContext(MedalContext);
  const btnRef = useRef();
  const btnRefSubmit = useRef();
  const auth = getAuth();
  const dispatch = useDispatch();

  const signin = () => {
    Notiflix.Loading.pulse();

    // xử lý chuổi phone number
    // const phone = VN.concat(phoneNumber.slice(1))
    // // gửi yêu cầu otp bằng sđt
    // if (phone === "") return;
    // // bảo mật firbase
    // let verify = new RecaptchaVerifier('recaptcha-container', {
    //   'size': 'invisible'
    // }, auth);
    // đăng nhập bằng sđt
    // signInWithPhoneNumber(auth, phone, verify)
    //   .then((result) => {
    //     setResult(result);
    //     setStep('VERIFY_OTP');
    //     setShow(true)
    //     Notiflix.Loading.remove()
    //   }).catch((error) => {
    //     console.log(error);
    //     Notiflix.Notify.warning('có lỗi');
    //   });
    loginwidthzalo(phoneNumber)
      .then((res) => {
        Notiflix.Loading.remove();
        console.log(res);
        setResult(result);
        setStep("VERIFY_OTP");
        setShow(true);
      })
      .catch((err) => console.log(err));
  };
  // validate otp
  const messaging = getMessaging();
  const ValidateOtp = () => {
    validateOtZalo({
      phoneNumber: phoneNumber,
      otp: OTP,
    })
      .then((res) => {
        console.log(res);
        setToken(res.data.access_token);
        setRender(Math.random());
        getUser().then((result) => {
          console.log(result);
          const info = result.data.role.some((item) => {
            return item == "ADMIN" || item == "SHOP";
          });
          info
            ? ""
            : Notiflix.Notify.warning(
                "Người dùng không có quyền admin hoặc shop"
              );
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // if (OTP === null) return;
    // // check mã otp
    // result.confirm(OTP).then((result) => {
    //   // console.log("sjahkjahdjasd0", result.user)
    //   // console.log("sjahkjahdjasd0", result.user.accessToken)
    //   console.log(result.user.accessToken);
    //   setToken(result.user.accessToken);
    //   setRender(Math.random())
    //   getToken(messaging, { vapidKey: 'BLqSs5cqFv2IpNgchCfWULuMR7rOlIjzFjSBBpLccKjcQ-b7DuEjS-6HyVx2ZW7ZORFytDzr0w-hLeBJP4GDRHA' })
    //     .then((result) => {
    //       login({ fcmToken: result })
    //         .then(res => {
    //           console.log(res);
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         })
    //     })
    //     .catch(err => {
    //       // setRender(Math.random());
    //       Notiflix.Notify.warning('Vui lòng bật thông báo để nhận được thông báo khi có đơn hàng mới')
    //     })
    //   getUser()
    //     .then((result) => {
    //       console.log(result)
    //       const info = result.data.role.some((item) => {
    //         return item == "ADMIN" || item == "SHOP"
    //       })
    //       info ?
    //         '' :
    //         Notiflix.Notify.warning('Người dùng không có quyền admin hoặc shop')
    //     })
    // })
    //   .catch((err) => {
    //     Notiflix.Notify.failure("Đăng nhập thất bại");
    //   })
  };

  const handlePress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      btnRef.current.click();
    }
  };

  const handlePressSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      btnRefSubmit.current.click();
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // const auth = getAuth();
    try {
      const res = await login(data.phone, data.password);
      setToken(res.data.access_token);
      setRender(Math.random());
      getUser().then((result) => {
        console.log(result);
        const info = result.data.role.some((item) => {
          return item == "ADMIN" || item == "SHOP";
        });
        info
          ? ""
          : Notiflix.Notify.warning(
              "Người dùng không có quyền admin hoặc shop"
            );
      });
    } catch (error) {
      Notiflix.Notify.warning("Sai tài khoản hoặc mật khẩu");
    }

    // const email = `${data.account}@bunny.com`;
    // signInWithEmailAndPassword(auth, email, data.password)
    //   .then((res) => {
    //     setToken(res.user.accessToken);
    //     getToken(messaging, {
    //       vapidKey:
    //         "BLqSs5cqFv2IpNgchCfWULuMR7rOlIjzFjSBBpLccKjcQ-b7DuEjS-6HyVx2ZW7ZORFytDzr0w-hLeBJP4GDRHA",
    //     })
    //       .then((result) => {
    //         console.log(result);
    //         login({ fcmToken: result })
    //           .then((res) => {
    //             console.log(res);
    //             setRender(Math.random());
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //       })
    //       .catch((err) => {
    //         setRender(Math.random());
    //         Notiflix.Notify.warning(
    //           "Vui lòng bật thông báo để nhận được thông báo khi có đơn hàng mới"
    //         );
    //       });
    //     // console.log(res);
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //     switch (error.message) {
    //       case "Firebase: Error (auth/wrong-password).":
    //         Notiflix.Notify.failure("sai mật khẩu");
    //         break;
    //       case "Firebase: Error (auth/user-not-found).":
    //         Notiflix.Notify.failure("tài khoản không tồn tại");
    //         break;

    //       default:
    //         break;
    //     }
    //   });
  };

  return (
    <RightPage>
      <div className="mb-[20px]">
        <img className="w-[250px] rounded-[15px]" src={logo} />
      </div>
      {/* tab ui login */}
      <div className="flex gap-[15px] pb-[20px]">
        <div
          className={`cursor-pointer flex items-center text-[20px] font-bold px-[15px] py-[10px]] rounded-md ${
            tab ? "text-[#000] bg-[#fff]" : "text-[#fff] bg-transparent"
          }`}
          onClick={() => setTab(true)}
        >
          Số điện thoại
        </div>
        <div
          className={`cursor-pointer flex items-center text-[20px] font-bold px-[15px] py-[10px] rounded-md ${
            tab ? "text-[#fff] bg-transparent" : "text-[#000] bg-[#fff]"
          }`}
          onClick={() => setTab(false)}
        >
          Tài khoản
        </div>
      </div>
      {/* pane login */}
      {tab ? (
        <WrapForm>
          <Heading>Đăng nhập</Heading>
          <InputForm>
            <Input
              onKeyPress={(e) => handlePress(e)}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder="Nhập số điện thoại"
            />
            <ImgForm ref={btnRef} onClick={signin}>
              <FaArrowRight size={24} color="#fff" />
            </ImgForm>
          </InputForm>
          <div id="recaptcha-container"></div>
          {show && (
            <>
              <div
                className="w-[300px] mt-[40px] mb-[30px]"
                onKeyPress={(e) => handlePressSubmit(e)}
              >
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  inputClassName="boderotp"
                />
                <ResendOTP onResendClick={signin} className="resendOtp" />
              </div>
              <BtnSubmit ref={btnRefSubmit} onClick={ValidateOtp}>
                Đăng nhập
              </BtnSubmit>
            </>
          )}
        </WrapForm>
      ) : (
        <WrapForm2 onSubmit={handleSubmit(onSubmit)}>
          <Heading>Đăng nhập</Heading>
          <input
            placeholder="nhập tài khoản"
            className="w-full outline-none p-[15px] border-[2px] border-solid border-[#707070] rounded-[10px] text-[17px]"
            {...register("phone", {
              required: true,
            })}
          />
          {errors?.account?.type === "required" && (
            <p className="text-[#FF0000] text-[14px]">
              Vui lòng không bỏ trống ô này
            </p>
          )}
          <input
            placeholder="nhập mật khẩu"
            className="w-full outline-none p-[15px] border-[2px] border-solid border-[#707070] rounded-[10px] text-[17px] mt-[20px]"
            {...register("password", {
              required: true,
            })}
          />
          {errors?.password?.type === "required" && (
            <p className="text-[#FF0000] text-[14px]">
              Vui lòng không bỏ trống ô này
            </p>
          )}
          <BtnSubmit type="submit">Đăng nhập</BtnSubmit>
        </WrapForm2>
      )}
    </RightPage>
  );
};
