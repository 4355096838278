import React, { useContext, useState } from "react";
import { MedalContext } from "../../context/Context";
import OrderManager from "../../pages/OrderManager/OrderManager";
import AccountManager from "../../pages/AccountManager/AccountManager";
import RequestReview from "../../pages/RequestReview/RequestReview";
import Statistical from "../../pages/Statistical/Statistical";
import SystemInstallation from "../../pages/SystemInstallation/SystemInstallation";
import Voucher from "../../pages/Voucher/Voucher";

function RightPage() {
  const { active } = useContext(MedalContext);

  const TabPane = () => {
    switch (active) {
      case 1:
        return <OrderManager />;

      case 2:
        return <AccountManager />;

      case 3:
        return <RequestReview />;

      case 4:
        return <Statistical />;

      case 5:
        return <SystemInstallation />;
      case 6:
        return <Voucher />;
      default:
        return;
    }
  };

  return <div className="h-full bg-[#F5F6FA]">{TabPane()}</div>;
}

export default RightPage;
