import { createSelector } from '@reduxjs/toolkit';

const productSelec = state => state.product.selected;
const sizeSelec = state => state.product.size;
const toppingSelec = state => state.product.topping;
export const getCart = state => state.product.cart;

export const totalMoney = createSelector(productSelec, sizeSelec, toppingSelec, (product, size, topping) => {
    // console.log(product, size, topping);
    const pros = size?.price ? (product.pro?.price + size?.price) * product.count : product.pro?.price * product.count
    // const sizes = size?.price
    const toppings = topping.reduce((total, item) => {
        return total + (item.item?.price * item.count)
    }, 0)
    return pros + toppings
})

export const orderProduct = createSelector(productSelec, sizeSelec, toppingSelec, totalMoney, (product, size, topping, total) => {
    // console.log(product);
    return {
        product,
        topping,
        size,
        total: total
        // total: product.pro?.price * product?.count
    }
})

export const totalOrderprice = createSelector(getCart, (cart) => {
    return cart.reduce((total, item) => {
        return total + item.total
    }, 0)
})