import tw from "tailwind-styled-components";

export const RC = {};

RC.Container = tw.div`
  rounded-[10px]
  overflow-auto
  flex-1
  bg-white
  flex
  flex-col
  px-[20px]
  m-[20px]
  bg-white
  shadow-[0_0_30px_-15px_rgba(0,0,0,0.3)]
`;

RC.Header = tw.div`
  text-[17px]
  font-semibold
  mb-[10px]
`;

RC.Body = tw.div`
  flex-1
  flex
`;

RC.BodyLeft = tw.div`
  w-[30%]
  h-full
`;

RC.BodyRight = tw.div`
  flex-1
  h-full
  px-[30px]
`;

RC.ChartAreaShadow = tw.div`
  w-[250px]
  h-[250px]
  mx-auto
  drop-shadow-2xl
`;

RC.ChartArea = tw.div`
  w-full
  h-full
`;

RC.DropdownToggle = tw.p`
  w-[280px]
  h-[60px]
  border
  border-[#BBBBBB]
  rounded-md
  flex
  items-center
  justify-between
  px-[20px]
  text-[25px]
`;

RC.DropdownButton = tw.button`
  w-[280px]
  h-[60px]
  border
  border-[#BBBBBB]
  rounded-md
  flex
  items-center
  justify-between
  px-[20px]
  text-[25px]
`;

RC.LabelTitle = tw.h3`
  text-[25px]
  font-bold
  mb-[60px]
`;

RC.LabelList = tw.div`
  flex
  flex-wrap
  gap-[20px]
`;

RC.LabelItem = tw.div`
  w-[40%]
  flex
  items-center
  text-[26px]
  mb-[30px]
`;

RC.LabelItemColor = tw.div`
  w-[30px]
  h-[30px]
  rounded-[4px]
  overflow-hidden
  mr-[18px]
`;

RC.LabelItemValue = tw.p`
  flex
  gap-[20px]
`;

RC.dropdownMenuStyle = {
  marginTop: 5,
  border: "none",
  padding: 0,
};
