import tw from 'tailwind-styled-components'

export const WrapForm = tw.div`
    bg-[#F5F6FA]
    flex-[1]
    h-full
    overflow-y-scroll
`
export const Layout = tw.div`
    px-[20px]
    h-full
    flex
    flex-col
`
export const NavList = tw.ul`
    mr-[40px]
    flex
    justify-between
    pb-[15px]
    pl-[13px]
    pr-[13px]
`
export const NavItem = tw.li`
    text-[15px]
    text-[#555555]
    font-bold
`
export const FormOderList = tw.div`
    flex-[1]
    h-full
    overflow-y-scroll
`