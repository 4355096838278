import AxiosClient from "./axiosClient";

const API_ENDPOINT = "/area";

export const getListArea = () => {
  return AxiosClient.get(API_ENDPOINT);
};

export const createArea = (params) => {
  return AxiosClient.get(API_ENDPOINT, { params: params });
};

export const uploadBanner = (data) => {
  return AxiosClient.post(API_ENDPOINT + "/upload-banner", data);
};
