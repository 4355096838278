import tw from 'tailwind-styled-components'

export const FormOderItem = tw.div`
    bg-white
    flex
    justify-between
    px-[13px]
    py-[20px]
    shadow-md
    mb-[20px]
`
export const Code = tw.div`
    w-[110px]
    flex
    flex-col 
`
export const Product = tw.div`
    w-[100px]
    flex
    flex-col 
`
export const Route = tw.div`
    w-[300px]   
`
export const Pay = tw.div`
    w-[200px]
`
export const Note = tw.div`
    w-[140px]
`
export const Status = tw.div`
    w-[179px]
    flex
    items-center
    h-fit
`
export const CodeNumber = tw.span`
    font-bold
    mb-[10px]
    break-all
`
export const Time = tw.span`
    text-[#555555]
`
export const From = tw.div`
    flex
    flex-col
    pb-[20px]
    border-b-[2px]
    mb-[20px]
`
export const To = tw.div`
    flex
    flex-col
`
export const Receiver = tw.div`
    flex
    mt-[10px]
`
export const FormTitile = tw.span`
    text-primary
    font-bold
    mb-[10px]
`
export const FormName = tw.span`
    font-bold
`
export const FormAddress = tw.span`
    
`
export const ToTitile = tw.span`
    text-primary
    font-bold
    mb-[10px]
`
export const ToName = tw.span`
    font-bold
`
export const ToAddress = tw.span`
    
`
export const ReceiverTitle = tw.span`
    text-primary
`
export const ReceiverInfo = tw.div`
    flex
    flex-col
    ml-[10px]
`
export const ReceiverInfoName = tw.span`
    font-bold
`
export const ReceiverInfoPhone = tw.span`
    font-bold
    mt-[7px]
`
export const Length = tw.div`
    flex
    mb-[10px]
`
export const LengthTitle = tw.div`
    text-[#555555]
`
export const LengthContext = tw.div`
    font-bold
    ml-[5px]
`
export const Ship = tw.div`
    flex
    mb-[10px]

`
export const ShipTitle = tw.div`
    text-[#555555]

`
export const ShipContext = tw.div`
    font-bold
    ml-[5px]
`
export const ShipPlus = tw.div`
    text-primary
`
export const Money = tw.div`
    flex
    mb-[10px]

`
export const MoneyTitle = tw.div`
    text-[#555555]

`
export const MoneyContext = tw.div`
    font-bold
    ml-[5px]
`
export const Total = tw.div`
    flex
    mb-[10px]

`
export const TotalTitle = tw.div`
    text-primary

`
export const TotalContext = tw.div`
    font-bold
    ml-[5px]
`
export const StatusBtn = tw.div`
    flex
    items-center
    justify-between
    w-[140px]
    h-[39px]
    px-[12px]
    border-2
    border-[#707070]
    rounded-[7px]
`
export const StatusBtnTitle = tw.div`
    text-[#0A00FF]
    font-bold
    mr-[10px]
`
export const StatusBtnImg = tw.img`
    w-[15px]
    h-[6px]
`
export const Dropdowns = tw.div`
    ml-[10px]
`
export const DropdownImg = tw.img`
    w-[30px]
`
export const TitleStatus = tw.span`
    font-bold 
    text-[14px]
`