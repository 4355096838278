import React, { useState, useEffect, useContext } from "react";
import {
  WrapForm,
  FormOderList,
  Layout,
  NavList,
  NavItem,
} from "./Pane.styles";
import {
  getListOrderByArea,
  getListOrderShopWithStt,
  getListOrderWithStt,
  getOrderByShip,
  getOrderByType,
} from "../../../services/orderServices";
import OderItem from "../../../components/UI/OderItem";
import { MedalContext } from "../../../context/Context";
import Notiflix, { Loading } from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import { render } from "../../../store/Rerender/RerenderSlice";

function Pane() {
  const user = useSelector((state) => state.auth.user);
  const { status, setStatus, renderPage, reRender } = useContext(MedalContext);
  const [oders, setOders] = useState([]);
  const [paginatedOders, setPaginatedOders] = useState([]);
  const [panage, setPanage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const onLoad = useSelector((state) => state.render);
  const role = useSelector((state) => state.role.role);
  const selectedCodeArea = useSelector((state) => state.area.codeArea);

  useEffect(() => {
    if (status) {
      if (role === "SUPERADMIN") {
        Notiflix.Loading.pulse();
        getListOrderByArea({ ...status, codeArea: selectedCodeArea })
          .then((orders) => {
            const sortedUsers = orders.data.data.sort((a, b) => {
              if (a.status === "PENDDING" && b.status !== "PENDDING") return -1;
              if (a.status !== "PENDDING" && b.status === "PENDDING") return 1;
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            // Phân trang tự động và kiểm tra nếu như trạng thái đang là PENDING thì thêm vào ở trang 1
            const paginatedUsers = [];
            let currentPage = [];
            sortedUsers.forEach((user) => {
              if (
                (currentPage.length < 10 && user.status === "PENDDING") ||
                currentPage.length < 10 ||
                user.status === "PENDDING"
              ) {
                currentPage.push(user);
              } else {
                paginatedUsers.push(currentPage);
                currentPage = [user];
              }
            });
            if (currentPage.length > 0) {
              paginatedUsers.push(currentPage);
            }

            setOders(sortedUsers);
            setPaginatedOders(paginatedUsers);
            setCountPage(paginatedUsers.length);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      } else if (role === "ADMIN") {
        Notiflix.Loading.pulse();
        getListOrderByArea({ ...status, codeArea: user?.codeArea })
          .then((oders) => {
            const sortedUsers = oders.data.data.sort((a, b) => {
              if (a.status === "PENDDING" && b.status !== "PENDDING") return -1;
              if (a.status !== "PENDDING" && b.status === "PENDDING") return 1;
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            // Phân trang tự động và kiểm tra nếu như trạng thái đang là PENDING thì thêm vào ở trang 1
            const paginatedUsers = [];
            let currentPage = [];
            sortedUsers.forEach((user) => {
              if (
                (currentPage.length < 10 && user.status === "PENDDING") ||
                currentPage.length < 10 ||
                user.status === "PENDDING"
              ) {
                currentPage.push(user);
              } else {
                paginatedUsers.push(currentPage);
                currentPage = [user];
              }
            });
            if (currentPage.length > 0) {
              paginatedUsers.push(currentPage);
            }

            setOders(sortedUsers);
            setPaginatedOders(paginatedUsers);
            setCountPage(paginatedUsers.length);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      } else {
        Notiflix.Loading.pulse();
        getListOrderShopWithStt({ ...status, codeArea: user?.codeArea })
          .then((oders) => {
            const sortedUsers = oders.data.data.sort((a, b) => {
              if (a.status === "PENDDING" && b.status !== "PENDDING") return -1;
              if (a.status !== "PENDDING" && b.status === "PENDDING") return 1;
              return new Date(b.createdAt) - new Date(a.createdAt);
            });

            // Phân trang tự động và kiểm tra nếu như trạng thái đang là PENDING thì thêm vào ở trang 1
            const paginatedUsers = [];
            let currentPage = [];
            sortedUsers.forEach((user) => {
              if (
                (currentPage.length < 10 && user.status === "PENDDING") ||
                currentPage.length < 10 ||
                user.status === "PENDDING"
              ) {
                currentPage.push(user);
              } else {
                paginatedUsers.push(currentPage);
                currentPage = [user];
              }
            });
            if (currentPage.length > 0) {
              paginatedUsers.push(currentPage);
            }

            setOders(sortedUsers);
            setPaginatedOders(paginatedUsers);
            setCountPage(paginatedUsers.length);
            setLoading(false);
            Notiflix.Loading.remove();
          })
          .catch((err) => {
            console.log(err);
            Notiflix.Loading.remove();
          });
      }
    } else {
      return;
    }
  }, [status, reRender, onLoad, panage, selectedCodeArea]);

  const handleLeft = () => {
    if (panage <= 1) {
      setPanage(1);
    } else {
      setPanage((prev) => prev - 1);
      setStatus({ ...status, page: panage - 1 });
    }
  };

  const handleRight = () => {
    if (panage >= countPage) {
      return;
    } else {
      setPanage((prev) => prev + 1);
      setStatus({ ...status, page: panage + 1 });
    }
  };

  useEffect(() => {}, [renderPage]);

  useEffect(() => {
    if (loading) Loading.pulse();
    else Loading.remove();
  }, [loading]);

  return (
    <WrapForm>
      <Layout>
        <NavList>
          <NavItem className="w-[110px]">Mã đơn</NavItem>
          <NavItem className="w-[100px]">Sản phẩm</NavItem>
          <NavItem className="w-[300px]">Lộ trình</NavItem>
          <NavItem className="w-[200px]">Thanh toán</NavItem>
          <NavItem className="w-[140px]">Ghi chú</NavItem>
          <NavItem className="w-[140px]">Trạng thái</NavItem>
        </NavList>
        <FormOderList>
          {paginatedOders[panage - 1]?.map((oder, index) => (
            <OderItem key={index} oder={oder} />
          ))}
        </FormOderList>
        <div className="w-full flex justify-center py-[20px] relative">
          <div className="w-fit flex text-center border-2 border-[#ccc]">
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleLeft}
            >
              &#x3c;
            </div>
            <div className="text-[23px] font-bold mx-[20px]">
              {countPage ? `${panage}/${countPage}` : panage}
            </div>
            <div
              className="text-[23px] font-bold bg-[#e0e0e0] px-[10px] text-primary hover:cursor-pointer"
              onClick={handleRight}
            >
              &#x3e;
            </div>
          </div>
        </div>
      </Layout>
    </WrapForm>
  );
}

export default Pane;