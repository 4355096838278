import tw from "tailwind-styled-components";

export const RF = {};

export const Input = tw.input`
  px-[10px]
  py-[7px]
  text-[15px]
  flex-1
  border-2
  border-[#ccc]
  rounded-tl-lg
  rounded-bl-lg
  outline-none
`
export const InputForm = tw.div`
  w-[300px]
  flex
  items-cennter
  justify-between
`
export const ImgForm = tw.div`
  flex 
  items-center 
  px-[22px] 
  bg-outline
  rounded-tr-lg
  rounded-br-lg
  hover:cursor-pointer
`
export const FormWrapper = tw.div`
    flex 
    justify-between 
    px-[20px] 
    pt-[25px]
`
export const TitileHeading = tw.span`
    text-[20px] 
    font-bold 
    text-primary
`
export const BtnFileExcel = tw.div`
    flex 
    items-center 
    px-[9px] 
    py-[6px] 
    border-[1px] 
    border-outline 
    rounded-default 
    w-[160px] 
    mt-[20px]
    hover:cursor-pointer
`
export const FormInfoUser = tw.div`
    flex 
    items-center
`
export const TextBtn = tw.span`
    text-[15px] 
    font-semibold 
    ml-[10px]
`
export const FormFilter = tw.div`
  relative
  w-[190px]
  hover:cursor-pointer
  mr-[25px]
`
export const DropdownINput = tw.div`
  flex
  justify-between
  p-[10px]
  border-[1px]
  border-outline
  rounded-[7px]
  bg-white
`

export const DropdownINputTxt = tw.span`
  text-[17px]
  font-semibold
`
export const FormControl = tw.div`
  flex 
  justify-between 
  mt-[25px]
`
export const DropdownList = tw.ul`
  absolute 
  top-[115%]
  w-full 
  border-[1px] 
  rounded-[7px] 
    border-outline 
  bg-white 
`
export const DropdownItem = tw.li`
  text-[17px]
  py-[10px]
  px-[10px]
  font-medium
  hover:bg-[#ccc]
`

RF.formPickerTime = tw.div`
  flex 
  absolute 
  p-5 
  bg-gray-200 
  border-[1px] 
    border-outline 
  rounded-lg 
  bottom-[-400%]
`

RF.FormControl = tw.div`
  mr-[15px]
`;

RF.Label = tw.div`
  flex
  gap-[10px]
  items-center
  text-[17px]
  font-bold
  mb-2
`;

RF.Button = tw.button`
  bg-outline
  rounded-[8px]
  py-[10px]
  px-[15px]
  whitespace-nowrap
  text-[15px]
  text-white
  font-bold
  w-[130px]
`;
RF.BtnToday = tw.button`
rounded-[8px]
py-[10px]
px-[15px]
whitespace-nowrap
text-[15px]
font-bold
bg-[#c9c8c5]
mt-[10px]
w-[130px]
`