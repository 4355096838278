import React, { useState, useEffect } from "react";
import AppProvider from "./providers/AppProvider";
import AppRoutes from "./routes/AppRoutes";
import { onlyFullHD } from "./utils/onlyFullHD";
import LoadingScreen from "./components/Loading/LoadingScreen";
import { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import Context from "./context/Context";
import Modals from "./components/UI/Modals/Modals";
import ModalEdit from "./components/UI/Modals/ModalEdit";
import { onMessageListener } from "./firebase/Firebase";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import { render } from "./store/Rerender/RerenderSlice";
import { useDispatch } from "react-redux";

import "./lib/fontawesome";
import "./lib/notiflix-init";
import "./lib/bootstrap.scss";
import { socketOn } from "./services/socketServices";

registerLocale("vi", vi);
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  useEffect(() => onlyFullHD(), []);
  const dispatch = useDispatch();

  useEffect(() => {
    initSocket();
  }, []);

  const initSocket = () => {
    // Connect event
    socketOn("connect", () => {
      console.log("Soket Connected");
    });

    // Disconnect event
    socketOn("disconnect", () => {
      console.log("Soket Disconnected");
    });
  };

  onMessageListener()
    .then((payload) => {
      if (payload.notification.title) {
        dispatch(render(Math.random()));
      }
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Context>
      {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      {/* <Notifications /> */}
      <AppProvider>
        <AppRoutes />
        <LoadingScreen />
      </AppProvider>
      <Modals />
      <ModalEdit />
    </Context>
  );
}

export default App;
